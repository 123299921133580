@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

:root {
  --font: brown;
}

body {
  background-color: #353535;
  background-image: linear-gradient(153deg, #353535 0%, #262626 100%);

  font-family: Poppins, sans-serif;
}

.container {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 100px;
  justify-content: center;
  transition: all ease-in-out 2s;
}

.box-one {
  display: grid;
  grid-template-rows: 160px 40px 40px 100px 80px;
  border-radius: 25px;
  border: 5px solid #e8e8e8;
  background-color: #e8e8e8;
  height: 600px;
  width: 250px;
  align-self: center;
  position: relative;
  left: 0%;
  animation: transitionInLeft 1.5s;
  overflow: hidden;
  align-content: center;
  transition: all ease-in-out 1s;
}

.box-two {
  grid-auto-rows: 50px 1fr;
  overflow: hidden;
  border-radius: 25px;
  border: 5px solid #e8e8e8;
  background-color: #e8e8e8;
  height: 600px;
  width: 850px;
  align-self: center;
  position: relative;
  right: 0;
  animation: transitionInRight 1.5s;
  transition: all ease-in-out 1s;
}

.avatar {
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  margin-top: 1.5rem;
  /* border: 5px white solid; */
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  justify-self: center;
  transition: opacity 3s;
  position: relative;
  top: 0px;
  animation: transitionInDown 3s ease-out;
}

.avatar img {
  width: 100%;
  height: 100%;
}

.header {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  color: #434343;
  transition: opacity 3s;
  position: relative;
  box-sizing: border-box;
}

.secondary {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #555555;
  transition: opacity 3s;
  position: relative;
}

.contact-container {
  display: grid;
  grid-template-columns: 50px 50px;
  justify-content: center;
  align-content: center;
  grid-gap: 5%;
  transition: opacity 2s;
  margin-top: 50%;
}

#name,
#email,
#message,
#submitbtn {
  opacity: 0;
  transition: opacity 3s;
}

.contact-container svg {
  cursor: pointer;
}

.navbar {
  height: 50px;
  display: grid;
  grid-template-columns: 300px 300px 300px;
  justify-content: center;
}

#about {
  display: flex;
  justify-content: center;
  border-bottom: solid #282c34;
}

#work {
  display: flex;
  justify-content: center;
  border-bottom: none;
}

#hire {
  display: flex;
  justify-content: center;
  border-bottom: none;
}

#hire span:hover {
  cursor: pointer;
}

#work span:hover {
  cursor: pointer;
}
#about span:hover {
  cursor: pointer;
}

.navbar-item {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #555555;
  transition: opacity 3s;
  position: relative;
  justify-self: center;
}

.services {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #434343;
}

.services-holder {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 325px 325px;
  align-content: center;
  justify-content: center;
  grid-gap: 25px;
}

.services span {
  margin-top: 10%;
}

.service {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 2rem;
}

.icon {
  display: grid;
  /* height: 50px; */
  align-content: center;
}

.work-info {
  grid-auto-rows: 50px 1fr;
  overflow: hidden;
  border-radius: 25px;
  /* border: 5px solid #e8e8e8;
  background-color: #e8e8e8; */
  height: 600px;
  /* width: 400px; */
  align-self: center;
  position: relative;
  right: 0;
  /* animation: transitionInRight 1s; */
  transition: width 1.5s;
  /* animation: expandBox 2s; */
  visibility: hidden;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.hire-info {
  grid-auto-rows: 50px 1fr;
  overflow: hidden;
  border-radius: 25px;
  /* border: 5px solid #e8e8e8;
  background-color: #e8e8e8; */
  height: 600px;
  /* width: 400px; */
  align-self: center;
  position: relative;
  right: 0;
  /* animation: transitionInRight 1s; */
  transition: width 1.5s;
  /* animation: expandBox 2s; */
  visibility: hidden;
}

.work {
  height: 850px;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}

.work-divider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.work-showcase {
  background-size: cover;
  background-position: top;
  height: 100%;
  animation: fadeIn 1s ease-in;
  /* opacity: ; */
  transition: opacity 1s;
  /* transition: transform 25s ease-in;
  transform: scale(1.5); */
  transition: background-image 1.5s;
  border-radius: 25px;
  height: 540px;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.work-showcase img {
  height: min-content;
}

.work img {
  object-fit: contain;
  object-position: center;
}

@keyframes transitionInLeft {
  from {
    left: -300%;
  }
  to {
    left: 0;
  }
}

@keyframes transitionInRight {
  from {
    right: -300%;
  }
  to {
    right: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes transitionInDown {
  from {
    top: -400px;
  }
  to {
    top: 0px;
  }
}

@keyframes expandBox {
  from {
    width: 0px;
  }
  to {
    width: 400px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.icon-showcase {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  /* margin-top:5%; */
  margin-left: 15%;
  margin-right: 15%;
  grid-gap: 15px;
  height: 20px;
  transition: opacity 3s;
}

.up {
  height: 40px;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.upper {
  height: 40px;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

.down {
  height: 40px;
  transform: translatey(0px);
  animation: reversefloat 6s ease-in-out infinite;
}

.downer {
  height: 40px;
  transform: translatey(0px);
  animation: reversefloat 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(0px);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
    transform: translatey(-5px);
  }
  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(0px);
  }
}

@keyframes reversefloat {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(0px);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
    transform: translatey(5px);
  }
  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(0px);
  }
}

.arrow {
  position: absolute;
  top: 50%;
  width: 3vmin;
  height: 3vmin;
  background: transparent;
  border-top: 1vmin solid white;
  border-right: 1vmin solid white;
  box-shadow: 0 0 0 lightgray;
  transition: all 200ms ease;
  border-color: orange;
}
.arrow.left {
  left: 1rem;
  transform: translate3d(0, -50%, 0) rotate(-135deg);
}
.arrow.right {
  right: 1rem;
  transform: translate3d(0, -50%, 0) rotate(45deg);
}
.arrow:hover {
  cursor: pointer;
  border-color: orange;
  box-shadow: 0.5vmin -0.5vmin 0 white;
}
.arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -60%) rotate(45deg);
  width: 200%;
  height: 200%;
}

.img {
  height: 600px;
  width: 850px;
}

.hire-bg {
}

.contact-form {
  height: 395px;
  /* margin-left:204px; */
  margin-top: 34px;
  padding: 1rem 2.5rem 1rem 2.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.hire-divider {
  display: grid;
}

.q-a {
  margin-top: 34px;
}

.question {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1rem;
  color: rgb(254, 254, 254);
}

.answer {
  color: rgb(198, 198, 200);
  /* text-shadow: 0 0 40px hsla(0,0%,99.6%,.75); */
}

.form-label {
  color: #434343;
  text-shadow: 0 0 40px hsla(0, 0%, 99.6%, 0.25);
}

.description {
  display: grid;
  grid-template-rows: 60px 80px 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.info-title {
  font-size: 2rem;
  font-weight: 600;
  color: #434343;
  /* text-align: center; */
}

.info-tech {
  font-size: 1rem;
  font-weight: 600;
  /* text-align: center; */
}

.tech {
  font-size: 1rem;
  font-weight: 500;
  color: #555555;
  display: grid;
  justify-items: center;
}

.info-description {
  font-size: 1rem;
  font-weight: 500;
  color: #555555;
  /* text-align: center; */
}

.buttons-container {
  display: grid;
  height: 130px;
  justify-items: center;
}

.buttons-container Button {
  height: 25px;
}

.btn-primary {
  height: 40px !important;
  width: 80px !important;
}

.faq {
  font-family: Poppins;
  font-weight: 600;
}

.faq h3 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  text-align: center;
  color: #434343;
}

.faq__question {
  max-width: 600px;
  margin: 30px auto;
  padding: 1rem;
  border-radius: 25px;
  border: solid 1px #d8d8d8;
}

.faq__toggle {
  list-style: none;
  cursor: pointer;
  transition: opacity 0.3s;
  color: #434343;
}

.faq__toggle:hover {
  opacity: 0.7;
}

.faq__answer {
  overflow: hidden;
  padding-bottom: 10px;
  border-top: solid 1px black;
  color: #212529;
  font-size: 0.9em;
  padding-top: 10px;
}

.faq__question[open] .faq__answer {
  transform: translateX(0);
}

.reveal_overlay {
  display: block;
  height: 300%;
  width: 100%;
  position: absolute;
  /* background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.6) 15%, rgba(0,0,0,0.8) 30%, #222); */
}

.faq__answer p {
}

.faq__question[open] .reveal_overlay {
  animation: reveal 0.8s ease-in-out forwards;
}

@keyframes reveal {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200%);
  }
}

.faq__question[open] .faq__answer {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

/*effect-underline*/
a.effect-underline:after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

a.effect-underline:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.bi:hover {
  filter: invert(30%) sepia(66%) saturate(3370%) hue-rotate(201deg)
    brightness(104%) contrast(106%);
  transition: filter 1s ease-out;
}

.header-tridery {
  font-weight: 500;
  font-style: normal;
  font-size: 2rem;
  text-align: center;
  text-shadow: 0 0 40px hsla(0, 0%, 99.6%, 0.25);
  color: #6a6a6a;
}

.bulb {
  height: 20px;
  filter: invert(91%) sepia(72%) saturate(5185%) hue-rotate(323deg)
    brightness(100%) contrast(103%);
  padding-bottom: 7px;
}

.hb {
  height: 25px;
  filter: invert(32%) sepia(76%) saturate(1796%) hue-rotate(334deg)
    brightness(97%) contrast(108%);

  padding-bottom: 7px;
}

.button {
  display: flex;
  justify-content: center;
  margin: 7%;
}

.work-btn {
  height: auto !important;
  width: auto !important;
}

#q1,
#q2,
#q3,
#q4,
#q5 {
  opacity: 0;
  transition: opacity 3s;
}

@media screen and (max-width: 901px) {
  .container {
    display: unset;
  }

  .box-one {
    width: 100%;
    border-radius: 0;
  }

  .box-two {
    border-radius: 0;
    width: 100%;
    height: unset;
  }

  .navbar {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .contact-container {
    margin-top: 1rem;
  }

  .services-holder {
    display: unset;
  }

  .work-info {
    width: 100% !important;
    border-radius: 0;
  }

  .services {
    margin-top: 4rem;
  }
}
